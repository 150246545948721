import facebook from '../../assets/social/facebook.svg';
import insta from '../../assets/social/insta.svg';
import mail from '../../assets/social/mail.svg';
import linkedin from '../../assets/social/linkedin.svg';
import twitter from '../../assets/social/twitter.svg';

import location from '../../assets/contact/location.svg';
import email from '../../assets/contact/mail.svg';
import phone from '../../assets/contact/phone.svg';
export const socialdata = [
    {
        image: linkedin,
        title: "",
        url: ""
    },
    {
        image: twitter,
        title: "",
        url: ""
    },
    {
        image: insta,
        title: "",
        url: "https://instagram.com/graphicnexwebservice?igshid=MzNlNGNkZWQ4Mg=="
    }
]

export const contactData = [
    {
        image: location,
        title: "Bichpuri, Califiornia",
        url: ""
    },
    {
        image: email,
        title: "avbc@gmail.com",
        url: ""
    },
    {
        image: phone,
        title: "1800 180 79459",
        url: ""
    },
]