const navigationActions = [
    {
        id: 'root',
        name: 'Home',
        path: '/',
    },
    {
        id: 'SERVICE-IMAGE-EDITING',
        name: 'Services',
        path: '/',
    },
    {
        id: 'ABOUT',
        name: 'About',
        path: '/',
    },
    // {
    //     id: '',
    //     name: 'Gallary',
    //     path: '/gallary',
    // },
    {
        id: 'FOOTER',
        name: 'Contact',
    }
]
export default navigationActions;